import './App.scss';

import React, { useEffect, useState, useRef } from 'react';

import { scaledImageUrl } from './components/ScaledImage';

import { v4 as uuidv4 } from 'uuid';
import playSound from './sound';

const feathers = require('@feathersjs/feathers');
const socketio = require('@feathersjs/socketio-client');
const io = require('socket.io-client');

const axios = require('axios');


const VIDEO_OPTION = false;

const QUESTION = "Lorem ipsum";

const COLORS = ['#433fbd', '#79a4cc', '#7F3DBE', '#5b39b0', '#6e4274'];

const ANSWERS = [
  { _id: 1, name: 'A', text: 'Answer 1', percent: 0 },
  { _id: 2, name: 'B', text: 'Answer 2', percent: 0 },
  { _id: 3, name: 'C', text: 'Answer 3', percent: 0 },
  { _id: 4, name: 'D', text: 'Answer 4', percent: 0 },
  { _id: 5, name: 'E', text: 'Answer 5', percent: 0 }
];

const MAX_REFRESH = 2000;
const REFRESH_TRESHOLD = 5000;

const SESSION_ID = uuidv4();


const App = props => {

  const app = useRef(null);

  useEffect(() => {

    console.log("SESSION ID", SESSION_ID);


  }, []);

  const [answers, setAnswers] = useState(ANSWERS);

  const [belongApp, setBelongApp] = useState({});
  const [module, setModule] = useState({});
  const [projection, setProjection] = useState(null);
  const [colors, setColors] = useState(null);

  const lastUpdate = useRef(0);
  const pendingUpdate = useRef(false);
  const updating = useRef(false);

  const moduleId = window.location.pathname.split("/").filter((p) => p !== "")[0];

  const receivedRefresh = (r = null) => {
    // if (!r || r.data._id.toString() == moduleId.toString()) {
    //   console.log(Date.now());
    //   console.log(lastUpdate.current);
    //   if (Date.now() - lastUpdate.current > MAX_REFRESH) { // ONLY REFRESH IF 5SEC PASSED
    //     app.current.service("projections").get(moduleId)
    //       .then( async p => {
    //         setProjection(p);
    //         lastUpdate.current = Date.now();
    //         pendingUpdate.current = false;
    //       });
    //   } else {
    //     pendingUpdate.current = true;
    //   }

    // }
    if (!r || !r.data._id || r.data._id.toString() == moduleId.toString()) {
      const refresher = !r || !r.data._id? "-" : r.data._id.toString();
      console.log(r)
      console.log(Date.now() - lastUpdate.current)
      if (Date.now() - lastUpdate.current > MAX_REFRESH) { // ONLY REFRESH IF 5SEC PASSED
        updating.current = true;
        lastUpdate.current = Date.now();
        pendingUpdate.current = false;

        app.current.service("projections").get(moduleId, { query: { sessionId: SESSION_ID, refresher } })
          .then( async p => {
            setProjection(p);
            updating.current = false;
          }).catch((e,s) => {
            console.log(e.code)
            //alert(JSON.stringify(e))
            updating.current = false;
          });
      } else {
        pendingUpdate.current = true;
      }
    }


  }

  useEffect(() => {

    const bodyTag = document.querySelector('body')
    bodyTag.style.backgroundColor = 'black';
    bodyTag.style.backgroundImage = `url('${module.backgroundImage||''}')`;
    bodyTag.style.backgroundSize = 'cover';
    bodyTag.style.backgroundPosition = 'center center';

    if (module.appId) {
      // app.current.service("apps").find({ query: { slug: module.appId } }).then( r => {
      //   if (r.data.length > 0) setBelongApp(r.data[0]);
      // })
      axios.get(`https://api25.ubelong.io/apps/?slug=${module.appId}`)
        .then(function (r) {
          if (r.data.data.length > 0) setBelongApp(r.data.data[0]);
        });
    }



  }, [module]);

  useEffect(() => {

    if (projection) {
      /* COUNTDOWN */
      let countDownDate = new Date("Jan 5, 2026 17:37:25").getTime();

      let x = setInterval(function() {

        let now = new Date().getTime();
        let distance = countDownDate - now;

        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById("countdown").innerHTML = minutes + "m " + seconds + "s ";

        if (distance < 0) {
          clearInterval(x);
          document.getElementById("countdown").innerHTML = "EXPIRED";
        }

      }, 1000);
    }


  }, [projection]);

  // On component load
  useEffect(() => {

    const socket = io('https://api25.ubelong.io', {
      transports: ["websocket"],
      forceNew: true,
      query: {
        platform: 'backoffice'
      }
    });
    app.current = feathers();

    // Set up Socket.io client with the socket
    app.current.configure(socketio(socket));

    let interval = null;

    socket.on("connect", (connection) => {

      app.current.service("modules").get(moduleId).then( r => {
        setModule(r);
        setColors(r.colors);
        receivedRefresh();
      })

    });

    // We watch for pending refreshes
    interval = setInterval(() => {
      if (pendingUpdate.current && ( Date.now() - lastUpdate.current > MAX_REFRESH ) ) receivedRefresh();
    }, REFRESH_TRESHOLD);

    app.current.service('projections').on('updated', receivedRefresh);

    return () => {
      app.current.service('projections').off('updated', receivedRefresh);
      clearInterval(interval);
    };

  }, []);

  const numberToLetter = number => String.fromCharCode(65 + number)

  // TEMP UPDATERS

  const updateData = (e) => {
    e.preventDefault();
    setAnswers( as => as.map( a => ({ ...a, percent: Math.random() }) ));
  }

  const setWinner = (e) => {
    e.preventDefault();
    setAnswers( as => as.map( a => a._id == 3 ? { ...a, correct: true } : a ));
  }

  const locale = belongApp.langPattern ? belongApp.langPattern.default : 'es';

  return (
    projection && colors ?
      <React.Fragment>
      {/*
        <div className="controls">
          <button onClick={updateData}>Update Data</button>
          <button onClick={setWinner}>Winner</button>
        </div>
      */}

        {!module.hideLogo &&
          <div className="logo" dataSessionId={SESSION_ID}>
            <img src={scaledImageUrl(belongApp.logo, 600, 200)}  alt="" />
          </div>
        }



        <div id="countdown" className="countdown" style={{opacity:0}}>...</div>

        <div className="background" style={{
          backdropFilter: `hue-rotate(${module.backgroundHue}deg)`,
          WebkitBackdropFilter: `hue-rotate(${module.backgroundHue}deg)`
        }}></div>

        <section className="chart-container">
          <div className="chart">
            {projection.options.map( (o, k) => (
              <div key={o._id} className={`bar bar--${o._id} ${o.correct ? 'winner' : ''}`}>
                <div className="bar__group" style={{ color: colors[k], transform: `scaleY(${Math.max(o.percent, 0.01)})` }}>
                  <div style={ o.correct ? { boxShadow: `0 0 5rem ${colors[k]}` } : {} } className="bar__face bar__face--top"></div>
                  <div style={ o.correct ? { boxShadow: `0 0 5rem ${colors[k]}` } : {} } className="bar__face bar__face--left"></div>
                  <div style={ o.correct ? { boxShadow: `0 0 5rem ${colors[k]}` } : {} } className="bar__face bar__face--front"></div>
                  <div style={ o.correct ? { boxShadow: `0 0 5rem ${colors[k]}` } : {} } className="bar__face bar__face--right"></div>
                </div>
                <div className="bar__caption">
                  <div className="bar__percentual" style={{ backgroundColor: colors[k] }}>
                    {parseInt(o.percent * 100)}%
                  </div>
                  <div className="bar__name" style={{ color: colors[k] }}>{numberToLetter(k)}</div>
                </div>
              </div>
            ))}
          </div>
        </section>


        <section className="legend-container">
            <div className="question">
              {VIDEO_OPTION ?
                <div className="video-wrap"></div>
              :
                <h2>{projection.title[locale]}</h2>
              }

            </div>
            <div className="answers">
              {projection.options.map( (o, k) => (
                <div style={ o.correct ? { backgroundColor: colors[k], boxShadow: `0 0 5rem ${colors[k]}` } : {}} className={`answer answer--${o._id} ${o.correct ? 'winner' : ''}`} key={o._id}>
                  <p style={{ color: o.correct ? 'rgba(255, 255, 255, 0.7)' : colors[k] }} className="answer__name">{numberToLetter(k)}</p>
                  <p className="answer__text">{o.title[locale]}</p>
                </div>
              ))}
            </div>
        </section>
      </React.Fragment>
    : null
  );
}

export default App;